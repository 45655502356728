import React, { useEffect, useState, useLayoutEffect } from "react";
// Firebase
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const Portfolios = ({ numToShow }) => {
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyD4AoOqZCnUp0DIL2XrK4XvI1nIwYp3v9Y",
      authDomain: "my-second-project-dd0cc.firebaseapp.com",
      projectId: "my-second-project-dd0cc",
      storageBucket: "my-second-project-dd0cc.appspot.com",
      messagingSenderId: "278001284720",
      appId: "1:278001284720:web:a60f34891d38cf9f2e66b5",
    };

    // init firebase
    initializeApp(firebaseConfig);

    // init services
    const db = getFirestore();

    // collection ref
    const colRef = collection(db, "portfolios");

    // get collection data
    getDocs(colRef).then((snapshot) => {
      let portfoliosDb = [];
      snapshot.docs.forEach((doc) => {
        portfoliosDb.push({ ...doc.data() });
      });
      setPortfolios(portfoliosDb);
    });
  }, []);

  useLayoutEffect(() => {
    // limit the title length
    if (window.matchMedia('(max-width:650px)').matches){
        const Portfoliotitle = document.querySelectorAll(".info h2");
    Portfoliotitle.forEach((title) => {
      title.style.fontSize = '15px'
      let titleText = title.textContent
      if(titleText.length > 10){
        let updatedText = title.textContent.slice(0 , 15) + '...'
        title.textContent = updatedText
      }
    });
    }
  });

  return (
    <div className="portfolios">
      {portfolios.slice(0, numToShow).map((portfolio) => (
        <div
          className="portfolio"
          key={portfolio.id}
          style={{
            border: `solid 2px ${
              portfolio.table_data.Type === "Ecommerce"
                ? "#00bcd4"
                : portfolio.table_data.Type === "Personal Website"
                ? "purple"
                : portfolio.table_data.Type === "Service Based"
                ? "yellow"
                : portfolio.table_data.Type === "Property Listing"
                ? "#d77653"
                : portfolio.table_data.Type === "Multi-vendor"
                ? "orange"
                : "red"
            }`,
          }}
        >
          <a href={`portfolio-details?id=${portfolio.id}`}>
            <img src={portfolio.Thumbnail} alt="Thumbnail" />
          </a>
          <div className="info">
            <h2>
              {portfolio.Name} - {portfolio.Year}
            </h2>
            <a href={`category?table_data.Type=${portfolio.table_data.Type}`}>
              <p>{portfolio.table_data.Type}</p>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Portfolios;
