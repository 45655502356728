import { NavLink } from "react-router-dom";

const FooterNavlinks = () => {
    return ( 
        <ul className="navLinks">

            <li><NavLink to="My Workflow">My Workflow</NavLink></li>

            <li><NavLink to="about">About Me</NavLink></li>

            <li><NavLink to="FAQ">FAQ</NavLink></li>

            <li><NavLink to="Privacy Policy">Privacy Policy</NavLink></li>
        </ul>
     );
}
 
export default FooterNavlinks;