import { NavLink } from "react-router-dom";
const NavLinks = () => {
  return (
    <ul className="navLinks">
      <li><NavLink to="/">Home</NavLink></li>
      <li><NavLink to="portfolio">Portfolio</NavLink></li>
      <li><NavLink to="about">About</NavLink></li>
      <li><NavLink to="workflow">Workflow</NavLink></li>
    </ul>
  );
};

export default NavLinks;
