const PageNotFound = () => {
    return ( 
        <div className= 'PageNotFound'>
            <h1>404</h1>
            <p>Oops! Page not found.</p>
            <p>Sorry, the page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <p>Go back to <a href="/">homepage</a>.</p>
        </div>
        
    );
}
 
export default PageNotFound;
