// Firebase
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



const firebaseConfig = {
  apiKey: "AIzaSyD4AoOqZCnUp0DIL2XrK4XvI1nIwYp3v9Y",
  authDomain: "my-second-project-dd0cc.firebaseapp.com",
  projectId: "my-second-project-dd0cc",
  storageBucket: "my-second-project-dd0cc.appspot.com",
  messagingSenderId: "278001284720",
  appId: "1:278001284720:web:a60f34891d38cf9f2e66b5"
};

// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()

// collection ref
const colRef = collection(db, 'portfolios')

// get collection data
getDocs(colRef)
.then((snapshot)=>{
  let portfoliosDb = []
  snapshot.docs.forEach((doc)=>{
    portfoliosDb.push({...doc.data()})
  })

  
})