import {FaGithub, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { IconContext } from 'react-icons';

const SocialIcons = () => {
    return ( 
        <div className="social-icons" >
            <IconContext.Provider value={{color:"#fff", size:"25px"}} >
            <a href="#"><FaGithub /></a>
            <a href=""><FaFacebook /></a>
            <a href=""><FaInstagram /></a>
            <a href=""><FaLinkedin /></a>
            </IconContext.Provider>
        </div>
     );
}
 
export default SocialIcons;
