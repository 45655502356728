import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import RootLayout from "./pages/rootLayout";
// pages
import Portfolio from "./pages/portfolio";
import Contact from "./pages/contact";
import Home from "./pages/home";
import PageNotFound from "./pages/404";
import PortfolioDetails from "./pages/portfolio-details";
import Category from "./pages/texonomies/category";
import Portfoliosdb from "./pages/texonomies/Portfolios";

// Backend
import Backend from "./backend/backend";
import CreatePortfolio from "./backend/create-portfolio";
import Fileupload from "./pages/fileUpload";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<RootLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="portfolio" element={<Portfolio />} />
      <Route path="portfolio-details" element={<PortfolioDetails />} />
      <Route path="category" element={<Category />} />
      <Route path="Fileupload" element={<Fileupload />} />
      <Route path="portfoliosdb" element={<Portfoliosdb />} />
      <Route path="*" element={<PageNotFound />} />

      {/* Backend */}
      <Route path="backend" element={<Backend />} />
      <Route path="backend/create-portfolio" element={<CreatePortfolio />} />
    </Route>
    // </Route>
  )
);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
