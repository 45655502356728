import React, { useState, useEffect } from "react";
import "./styles/portfolio_page.css";
import SocialIcons from "./elements/socialicons";
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const PortfolioDetails = () => {
  const [portfoliodata, setPortfoliodata] = useState(null);

  const id = new URLSearchParams(window.location.search).get("id");

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyD4AoOqZCnUp0DIL2XrK4XvI1nIwYp3v9Y",
      authDomain: "my-second-project-dd0cc.firebaseapp.com",
      projectId: "my-second-project-dd0cc",
      storageBucket: "my-second-project-dd0cc.appspot.com",
      messagingSenderId: "278001284720",
      appId: "1:278001284720:web:a60f34891d38cf9f2e66b5"
    };

    // Initialize Firebase
    initializeApp(firebaseConfig);
    
    // Initialize Firestore
    const db = getFirestore();
    
    const fetchPortfolio = async () => {
      try {
        const docRef = doc(db, "portfolios", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setPortfoliodata(data);
          document.title = data.Name ? `${data.Name}` : "Milad Ahmad";
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching portfolio details:", error);
      }
    };

    if (id) {
      fetchPortfolio();
    }
  }, [id]);

  if (!portfoliodata) {
    return <p>Loading...</p>;
  } else {
    return (
      <div className="portfolio-details">
        <div
          className="portfolios-details-container container"
          key={portfoliodata.id}
        >
          {/* Left */}
          <div className="portfolio-left">
            <div className="portfolio-main-imgs">
              {portfoliodata.hasOwnProperty("images") &&
                portfoliodata.images.map((image, index) => (
                  <img key={index} src={image} alt={`image ${index + 1}`} />
                ))}
              {portfoliodata.hasOwnProperty("image") && (
                <img src={portfoliodata.image} alt="image" />
              )}
            </div>
          </div>

          {/* Right */}
          <div className="portfolio-right">
            <div className="portfolio-right-content">
              <h1>
                {portfoliodata.Name} - {portfoliodata.Year}
              </h1>
              <p>{portfoliodata.Description}</p>
              <hr />
              <div className="portfolio-info-container">
                <div className="portfolio-info">
                  <span>Client</span>
                  <span>{portfoliodata.table_data.Client}</span>
                </div>
                <div className="portfolio-info">
                  <span>From</span>
                  <span>{portfoliodata.table_data.From}</span>
                </div>
                <div className="portfolio-info">
                  <span>Type</span>
                  <a href={`category?table_data.Type=${portfoliodata.table_data.Type}`}>
                    <span>{portfoliodata.table_data.Type}</span>
                  </a>
                </div>
                <div className="portfolio-info">
                  <span>Budget</span>
                  <span>{portfoliodata.table_data.Budget}</span>
                </div>
                <div className="portfolio-info">
                  <span>Duration</span>
                  <span>{portfoliodata.table_data.Duration}</span>
                </div>
              </div>
              <div className="portfolio-right-content-bottom-content">
                <div>
                  <a
                    href={portfoliodata.project_url}
                    target="_blank"
                    className="btn-primary"
                  >
                    View Project
                  </a>
                </div>
                <div>
                  <SocialIcons />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PortfolioDetails;
