import React, { useEffect, useState } from "react";
import "./styles/portfolios.css";
// Firebase
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const Portflio = () => {

  const [portfolios, setPortfolios] = useState([]);
  const [filterTypes, setFilterType] = useState(["All"]); // Initialize filter type

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyD4AoOqZCnUp0DIL2XrK4XvI1nIwYp3v9Y",
      authDomain: "my-second-project-dd0cc.firebaseapp.com",
      projectId: "my-second-project-dd0cc",
      storageBucket: "my-second-project-dd0cc.appspot.com",
      messagingSenderId: "278001284720",
      appId: "1:278001284720:web:a60f34891d38cf9f2e66b5"
    };
    
    // init firebase
    initializeApp(firebaseConfig)
    
    // init services
    const db = getFirestore()
    
    // collection ref
    const colRef = collection(db, 'portfolios')
    
    // get collection data
    getDocs(colRef)
    .then((snapshot)=>{
      let portfoliosDb = []
      snapshot.docs.forEach((doc)=>{
        portfoliosDb.push({...doc.data()})
      })
      setPortfolios(portfoliosDb)
    })
  }, []);

  // Handler for changing filter type
  const handleFilterChange = (types) => {
    setFilterType(types);
  };

  // Filter portfolios based on the selected types
  const filteredPortfolios = filterTypes.includes("All")
    ? portfolios
    : portfolios.filter((portfolio) =>
        filterTypes.includes(portfolio.table_data.Type)
      );


  
  return (
    <div className="portfolios-container container">
      <p>
        <i>
          Note: These are just a few examples of the hundreds of projects I’ve
          completed for clients from various countries.
        </i>
      </p>
      <div className="filter-portfolios">
        <ul>
          <li onClick={() => handleFilterChange(["All"])}>All</li>
          <li onClick={() => handleFilterChange(["Ecommerce"])}>ECOMMERCE</li>
          <li
            onClick={() =>
              handleFilterChange([
                "LOCAL BUSINESS",
                "Service Based",
                "Non-Profit Organization",
              ])
            }
          >
            LOCAL BUSINESS
          </li>
          <li onClick={() => handleFilterChange(["Service Based"])}>
            SERVICE BASED
          </li>
          <li onClick={() => handleFilterChange(["Personal Website"])}>
            PORTFOLIO
          </li>
          <li
            onClick={() =>
              handleFilterChange(["REAL ESTATE", "Property Listing"])
            }
          >
            REAL ESTATE
          </li>
          <li onClick={() => handleFilterChange(["Multi-vendor"])}>
            MARKET-PLACE
          </li>
        </ul>
      </div>
      <div className="portfolios">
        {filteredPortfolios.length > 0 ? (
          filteredPortfolios.map((portfolio) => (

            <div className="portfolio" key={portfolio.id} style={{ border: `solid 2px ${
              portfolio.table_data.Type === 'Ecommerce' ? '#00bcd4' :
              portfolio.table_data.Type === 'Personal Website' ? 'purple' :
              portfolio.table_data.Type === 'Service Based' ? 'yellow' :
              portfolio.table_data.Type === 'Property Listing' ? '#d77653' :
              portfolio.table_data.Type === 'Multi-vendor' ? 'orange' :
              'red'
          }` }}>
              <a href={`portfolio-details?id=${portfolio.id}`}>
                  <img src={portfolio.Thumbnail} alt="Thumbnail" />
              </a>
              <div className="info" >
                      <h2>
                          {portfolio.Name} - {portfolio.Year}
                      </h2>
                      <a href={`category?table_data.Type=${portfolio.table_data.Type}`}>
                          <p>{portfolio.table_data.Type}</p>
                      </a>
                  </div>
          </div>
           
          ))
        ) : (
          <p>No portfolios match the selected filter.</p>
        )}
      </div>
    </div>
  );
};

export default Portflio;
