import { useEffect, useState } from "react";

const Category = () => {
  const [Category, setCAtegory] = useState([]);

  const categoryUrl = new URLSearchParams(window.location.search).get(  "table_data.Type" );

  useEffect(() => {
    const fetchCategory = async () => {
      const res = await fetch(`http://localhost:8000/portfolios?table_data.Type=${categoryUrl}`);
      const data = await res.json()
      setCAtegory(data)
    };
    fetchCategory()
  }, [categoryUrl]);

  return (
    <div className="catagory page-container">
        {Category.length === 1 ? (
            <p>Showing total of 1 result</p>
        ) : (
            <p>Showing total of {Category.length} results</p>
        )}
        <div className="portfolios">
        {Category.length > 0 ? (
            Category.map((cat) => (
                <a href={`portfolio-details?id=${cat.id}`}>
              <div className="portfolio" key={cat.id}>
              <img src={cat.Thumbnail} alt="Thumbnail" />
              <div className="info">
                <h2>
                  {cat.Name} - {cat.Year}
                </h2>
                <a href={`category?table_data.Type=${cat.table_data.Type}`}>
                <p>{cat.table_data.Type}</p>
                </a>
              </div>
            </div>
            </a>
            ))
        ) : (
            <p>Cant find any Category data</p>
        )}
        </div>
  </div>
  )
  
};

export default Category;
