import { Link } from "react-router-dom";
const Logo = () => {
    return ( 
        <div className="logo">
        <h3>
          <Link to="/">Milad Ahmad</Link>
        </h3>
      </div>
     );
}
 
export default Logo;