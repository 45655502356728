import { useState } from "react";
import "../styles/headerSidebar.css";
import { RiMenu2Line, RiCloseFill  } from "react-icons/ri";
import NavLinks from "./navlinks";
import Logo from "./logo";
import SocialIcons from "./socialicons";

const HeaderSidebar = () => {
    // Using useState to handle the state of the sidebar visibility
    const [isActive, setIsActive] = useState(false);

   
    const toggleSidebar = () => {
        setIsActive((isActive) => {
          const first = document.querySelector('.hero .first');
          first.style.zIndex = isActive ? '1' : '0';
          return !isActive;
        });
      };

    return (
        <div className={`headerSidebar ${isActive ? 'enable' : ''}`}>
            <div className="menuIcon" onClick={toggleSidebar}>
                <RiMenu2Line />
            </div>
            <div className={`outerContainer ${isActive ? 'enable' : ''}`}>
                <div className="closeIcon" onClick={toggleSidebar}>
                    <div className="icon">
                        <RiCloseFill />
                    </div>
                </div>
                <div className="headerSidebarContent">
                    <Logo />
                    <hr />
                    <div className="navLinks">
                        <NavLinks />
                    </div>
                    <hr />
                    <SocialIcons />
                </div>
            </div>
        </div>
    );
};

export default HeaderSidebar;
