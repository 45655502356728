import FooterNavlinks from "./footerNavlinks";
import '../styles/footer.css'

const Footer = () => {
    return ( 
       <footer>
            <div className="footerContainer">
                <div className="branding">
                    <div className="logo">
                        <a href="/">
                        <img src="assets/imgs/miladahmad-logo-.png" alt="" />
                        </a>
                    </div>
                </div>

                <div className="navLinks">
                    <FooterNavlinks />
                </div>
            </div>
            <div className="bottomText">
                    <p>Copyright 2024 © Milad Ahmad. All Rights Reserved</p>
                </div>
       </footer>
     );
}
 
export default Footer;