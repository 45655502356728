import { useEffect, useState, useRef } from "react";
// ELements
import SocialIcons from "./elements/socialicons";
import Portfolio from "./texonomies/Portfolios";

import "./styles/home_page.css";

// icons
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

const Home = () => {
  // Hero Section - Auto Type
  useEffect(() => {
    const myName = document.querySelector(".myName");
    const myNameText = "MILAD";
    let index = 0;

    function autoType() {
      myName.innerText = myNameText.slice(0, index);
      index++;
      if (index > myNameText.length) {
        clearInterval(intervalId); // Clear the interval when typing is completed
      }
    }
    const intervalId = setInterval(autoType, 300);
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Slider functionality
  const workFlowsRef = useRef([]);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // State to track if the device is mobile
  const [isMobile] = useState(window.matchMedia("(max-width: 650px)").matches);

  useEffect(() => {
    if (isMobile) {
      const updateSlider = () => {
        workFlowsRef.current.forEach((workFlow, index) => {
          // Display the current slide and hide others
          workFlow.style.display = index === currentIndex ? "flex" : "none";
        });
      };

      const handlePrev = () => {
        // Go to the previous slide or wrap around to the last slide
        setCurrentIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : workFlowsRef.current.length - 1
        );
      };

      const handleNext = () => {
        // Go to the next slide or wrap around to the first slide
        setCurrentIndex((prevIndex) =>
          prevIndex < workFlowsRef.current.length - 1 ? prevIndex + 1 : 0
        );
      };

      updateSlider(); // Initial display

      prevButtonRef.current.addEventListener("click", handlePrev);
      nextButtonRef.current.addEventListener("click", handleNext);

      return () => {
        prevButtonRef.current.removeEventListener("click", handlePrev);
        nextButtonRef.current.removeEventListener("click", handleNext);
      };
    } else {
      // Display all workFlow divs as block elements for non-mobile screens
      workFlowsRef.current.forEach(
        (workFlow) => (workFlow.style.display = "block")
      );
    }
  }, [currentIndex, isMobile]);
  return (
    <div className="home-page">
      {/* Hero Banner */}
      <div className="hero">
        <div className="first">
          <h1>
            HI <span className="shake">👋🏻</span> I'M{" "}
            <span className="standoutText myName"></span>
          </h1>
          <h2>
            I Create User-friendly<span className="standoutText mName"></span>{" "}
            <strong>Ecommerce stores</strong> and{" "}
            <strong>Web Experiences</strong> 💻{" "}
          </h2>
          <SocialIcons />
        </div>
        <div className="second">
          <div className="img">
            <img src="laad.webp" alt="" />
          </div>
        </div>
      </div>

      {/* Second Section */}
      <section className="secondSection">
        <div className="secondSectionContainer">
          <div className="first">
            <p className="standoutText">IN FREELANCING FOR 7 YEARS</p>
            <h2>How I Work with My Clients</h2>
          </div>
          <div className="second">
            <div
              className="workFlow"
              ref={(el) => (workFlowsRef.current[0] = el)}
            >
              <img
                src="assets/imgs/w-developer-icon-1-64x64.png"
                alt="Step 1: Concepts"
              />
              <h3>Step 1: Concepts</h3>
              <p>
                Taking into account your wishes, I draw up and make sketches of
                the appearance.
              </p>
            </div>

            <div
              className="workFlow"
              ref={(el) => (workFlowsRef.current[1] = el)}
            >
              <img
                src="assets/imgs/w-developer-icon-2-64x64.png"
                alt="Step 2: Design"
              />
              <h3>Step 2: Design</h3>
              <p>
                The step is when you see how all your ideas are worked out and
                brought to life.
              </p>
            </div>

            <div
              className="workFlow"
              ref={(el) => (workFlowsRef.current[2] = el)}
            >
              <img
                src="assets/imgs/w-developer-icon-3-64x64.png"
                alt="Step 3: Develop"
              />
              <h3>Step 3: Develop</h3>
              <p>
                I make edits to the implemented project, and check the quality
                of the work done.
              </p>
            </div>
          </div>
        </div>
        {/* Slider navigation buttons, displayed only on mobile */}
        {isMobile && (
          <div className="slider-nav">
            <button className="prev" ref={prevButtonRef}>
              <GrFormPrevious />
            </button>
            <button className="next" ref={nextButtonRef}>
              <GrFormNext />
            </button>
          </div>
        )}
      </section>

      {/* Third Section */}
      <div className="thirdSection">
        <div className="thirdSectionContainer">
          <div className="first">
            <img src="assets/imgs/miladAhmad67.png" alt="miladAhmad67" />
            <br />
            <h3>Take a Look at My Portfolio</h3>
            <p>
              All these projects were completed on time and delivered to the
              Clients.
              <br /> <br /> Do you want the same?
            </p>
            <div className="buttons">
              <a href="/contact" className="gradientBorder">
                Contact me
              </a>
              <a href="/portfolio" className="classicBtn">
                View More
              </a>
            </div>
          </div>
          <div className="second">
            <div className="thirdSectionPortfolios">
              <Portfolio numToShow={6} />
            </div>
          </div>
        </div>
      </div>

      {/* Fourth Section */}
      <div className="fourthSection" id="AboutMe">
        <div className="fourthSectionContainer">
          <div className="first">
            <img src="/assets/imgs/About me.png" alt="" />
          </div>
          <div className="second">
            <p className="standoutText">About Milad</p>
            <h2>HOW IT ALL STARTED</h2>
            <h4>Background</h4>
            <p>
              My adventure in the digital world started when I got
              super curious about how the internet does its thing. I started
              playing around with coding when I was pretty young, and my love
              for making cool-looking and super useful websites pushed me to
              jump into the world of web design and development as a career.
              </p>
              <h4>Expertise</h4>
              <p>As someone who's been doing website design and
              development for a while, I've been lucky to team up with clients
              from all over the world. Whether it's the busy streets of Tokyo or
              the lively markets of Marrakech, my portfolio is like a mixtape of
              different places. It shows how I can adapt and get what's going on
              in different cultures.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
