import React from "react";

const FileInput = () => {
  const upload = (e) => {
    console.warn(e.target.files[0]);
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("img", files);

    fetch("http://localhost:8000/portfolios", {
      method: "POST",
      body: formData,
    }).then((res) => {
      res.json().then((result) => {
        console.warn(result);
      });
    });
  };

  return (
    <div>
      <h1>Upload File in React.js</h1>
      <input type="file" onChange={(e) => upload(e)} name="img" />
    </div>
  );
};

export default FileInput;
