// Header1.js
import React from "react";
import NavLinks from "../elements/navlinks";
import Logo from "../elements/logo";
import HeaderSiderbar from "../elements/HeaderSidebar";

const Header1 = () => {
  return (
   <div className="headerOne">
     <header>
        <Logo />
        <nav>
          <NavLinks />
        </nav>
        <div className="HeaderSiderbar">
        <HeaderSiderbar />
        </div>
      </header>
   </div>
  );
};

export default Header1;
