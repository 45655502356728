// Header2.js
import React from "react";
import { Link } from "react-router-dom";
import NavLinks from "../elements/navlinks";
import Logo from "../elements/logo";
import HeaderSiderbar from "../elements/HeaderSidebar";


const Header2 = () => {
  return (
    <header>
        <Logo />
        <nav>
          <NavLinks />
        </nav>
        <div className="HeaderSiderbar">
        <HeaderSiderbar />
        </div>
        <div className="contact-btn">
          <Link to="contact" className=" gradientBorder">
            Contact Me
          </Link>
        </div>
      </header>
  );
};

export default Header2;
