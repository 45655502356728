import { Outlet, useLocation } from "react-router-dom";
import "./styles/header.css";
import Header1 from "./headers/header1";
import Header2 from "./headers/header2";
import Footer from "./elements/footer";

const RootLayout = () => {
  document.title = "Home";

  // Access pathname from the object returned by useLocation()
  const { pathname } = useLocation();

  return (
    <div className="RootLayout">
      {pathname.endsWith('/') ?  <Header1/> : <Header2/>}

      <main>
        <Outlet />
      </main>

      < Footer/> 
    </div>
  );
};

export default RootLayout;
