import { NavLink } from "react-router-dom";


import Logo from "../pages/elements/logo";
import './styles/backend.css'

const Backend = () => {
  return (
    <div className="backend">
      <div className="first">
        <div className="branding">
          <Logo />
        </div>
        <div className="admin-list-items">
          <ul>
            <li><NavLink to='/backend/create-portfolio'>Portfolios</NavLink></li>
            <li>Pages</li>
            <li>Forms</li>
          </ul>
        </div>
      </div>
      <div className="second">
        <div className="content">
          <h1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima
            omnis, saepe molestias quod ipsa iure quae aperiam aliquam rerum at
            praesentium doloribus dolore ipsum temporibus eius consequuntur!
            Quam, exercitationem maiores!
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Backend;
