import './styles/create-portfolio.css';

const CreatePortfolio = () => {
    const postPortfolio = async (e) => {
        e.preventDefault();
        
        const doc = {
            Name: e.target.Name.value,
            Year: e.target.Year.value,
            Description: e.target.Description.value,
            Thumbnail: e.target.Thumbnail.value,
            image: e.target.image.value,
            project_url: e.target.project_url.value,
            table_data: {
                Client: e.target.client.value,
                From: e.target.From.value,
                Type: e.target.type.value,
                Budget: e.target.budget.value,
                Duration: e.target.duration.value
            }
        };

        await fetch('http://localhost:8000/portfolios', {
            method: 'POST',
            body: JSON.stringify(doc),
            headers: {'Content-Type': 'application/json'}
        });
    };

    return ( 
        <div className="CreatePortfolio">
            <div className="container">
                <h1>My Portfolio</h1>
                <form id="portfolio-form" onSubmit={postPortfolio}>
                    <div className="form-row">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="Name" required />
                    </div>
                    <div className="form-row">
                        <label htmlFor="year">Year</label>
                        <input type="text" id="year" name="Year" defaultValue='2024' />
                    </div>
                     <div className="form-row">
                        <label htmlFor="description">Description</label>
                        <textarea id="description" name="Description" ></textarea>
                    </div>
                    <div className="form-row">
                        <label htmlFor="thumbnail">Thumbnail Image (URL)</label>
                        <input type="url" id="thumbnail" name="Thumbnail"  />
                    </div>
                    <div className="form-row">
                        <label htmlFor="image">Main Image (URL)</label>
                        <input type="url" id="image" name="image"  />
                    </div>
                    <div className="form-row">
                        <label htmlFor="project_url">Project URL</label>
                        <input type="url" id="project_url" name="project_url"  />
                    </div>
                    <div className="form-row">
                        <h3>Table Data</h3>
                        <div className="table-data">
                            <label htmlFor="client">Client</label>
                            <input type="text" id="client" name="Client" />
                        </div>
                        <div className="table-data">
                            <label htmlFor="From">From</label>
                            <input type="text" id="From" name="From"  />
                        </div>
                        <div className="table-data">
                            <label htmlFor="type">Type</label>
                            <input type="text" id="type" name="Type" defaultValue={'Ecommerce'} />
                        </div>
                        <div className="table-data">
                            <label htmlFor="budget">Budget</label>
                            <input type="text" id="budget" name="Budget" defaultValue={'$$$$'} />
                        </div>
                        <div className="table-data">
                            <label htmlFor="duration">Duration</label>
                            <input type="text" id="duration" name="Duration" defaultValue={'15 days'} />
                        </div>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
     );
}
 
export default CreatePortfolio;
