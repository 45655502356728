import './styles/contact.css'





const Contact = () => {
  return (
    <div className="contact">
      <h1>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem, ipsam.
        Dicta id ducimus, iure neque recusandae aliquam et accusamus accusantium
        quod perspiciatis?
      </h1>
    </div>
  );
};

export default Contact;
